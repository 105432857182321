<template>
<v-container>
    <form @submit.prevent="submit">
        <v-row dense class="p-relative zindex-1">
            <v-col cols="12" sm="6">
                <v-text-field ref="password" :type="showPassword ? 'text' : 'password'" v-model="password" :disabled="loading" label="Password" :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="showPassword = !showPassword" required :rules="[rules.required, rules.minLength6]" class="pt-0 mt-0" outlined color="dark" placeholder="Enter a password...">
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field :type="showPassword ? 'text' : 'password'" v-model="confirmPassword" :disabled="loading" label="Confirm password" :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="showPassword = !showPassword" required :rules="[rules.isMatch]" class="pt-0 mt-0" color="dark" outlined placeholder="Confirm password..."></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-btn block x-large color="dark" dark type="submit" :loading="loading">
                    Save password
                    <v-icon color="warning" class="ml-2">mdi-content-save-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </form>
</v-container>
</template>

<script>
// Services
import Contact from '@/modules/meo-module/contact';
import Persona from '@/modules/meo-module/persona';
import Countries from '@/modules/countries';

export default {
    name: 'profile-security',
     meta: {
        titleTemplate: "Security |  %s"
    },

    data() {
        return {
            loading: false,
            error: false,
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            email: '',
            country: {},
            showPassword: false,
            rules: {
                required: value => !!value || 'This field is required',
                minLength3: value => value.length >= 3 || 'Min 3 characters',
                minLength4: value => value.length >= 4 || 'Min 4 characters',
                minLength6: value => value.length >= 6 || 'Min 6 characters',
                isMatch: value => value === this.confirmPassword,
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail'
                },
            }
        }
    },

    computed: {
        countries() {
            return Object.keys(Countries).map(key => {
                return {
                    name: Countries[key].name,
                    code: key,
                    emoji: Countries[key].emoji,
                    unicode: Countries[key].unicode,
                    image: Countries[key].image
                };
            });
        }
    },

    methods: {
        async submit() {
            this.loading = true;

            let user = this.$meo.user;

            if (user) {
                let personaPayload = {
                    password: this.password,
                    confirmPassword: this.confirmPassword
                };

                let updatedPersona = await Persona.update(user.persona, personaPayload).then(({ data }) => data);
            }

            this.loading = false;
        }
    }
}
</script>

<style lang="scss">
</style>
