import FluroContent from "./fluro-content";

class Persona extends FluroContent {
    constructor() {
        super("persona");
    }

    list(options) {
        return super.list(options);
    }

    get(id, options) {
        return super.get(id, options);
    }

    create(payload, options) {
        return super.create(payload, options);
    }

    update(id, payload, options) {
        return super.update(id, payload, options);
    }

    delete(id, options) {
        return super.delete(id, options);
    }
}

const _Persona = new Persona();
export default _Persona;